import { FC, ReactNode } from 'react';

import styles from './PageLoader.module.css';

export const PageLoader: FC<{ children?: ReactNode; isPage?: false; isAbsolute?: boolean }> = ({
  children,
  isAbsolute,
}) => {
  return (
    <div
      className={`${styles.container} pageLoader ${isAbsolute ? `${styles.positionAbsolute}` : ''}`}
    >
      <div className={styles.dots}>
        <div className={styles.dot} />
        <div className={styles.dot} />
        <div className={styles.dot} />
      </div>

      {children && <div className={styles.content}>{children}</div>}
    </div>
  );
};
