import { atom } from 'jotai';

import { RegionAtom } from './RegionAtom.entity';

export const regionAtomDefaultValue = {
  key: 'any_region',
  displayName: 'Any Region',
  region: 'anyregion',
  location: {
    lat: 0,
    lon: 0,
  },
  postcode: '',
  regionCode: '',
};

export const regionAtom = atom<RegionAtom>(regionAtomDefaultValue);
