import { atom } from 'jotai';

import { RegionAreaAtom } from '../RegionAtom/RegionAtom.entity';

export const areaAtomDefaultValue = {
  key: 'any_area',
  displayName: 'Any Area',
  region: 'anyarea',
  location: {
    lat: 0,
    lon: 0,
  },
  postcode: '',
  areaCode: '',
};

export const areaAtom = atom<RegionAreaAtom>(areaAtomDefaultValue);
