import { atom } from 'jotai';

import { LocationAggregationState } from './LocationAggregationEntity';

export const initialLocationAggregationsState: LocationAggregationState = {
  error: false,
  isLoading: false,
  regions: [],
  areas: [],
};

export const locationAggregationsAtom = atom<LocationAggregationState>({
  ...initialLocationAggregationsState,
});
