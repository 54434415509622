import { Locale } from '@core/Entities/Locale/Locale.entity';
import { Location } from '@gds/Icons/Paths/Location';

import { Input } from '@gds/Input/Input';

import usePostcodeData from 'Components/SearchWidget/Hooks/UsePostcodeData';

import styles from './Postcode.module.css';

interface PostcodeProps {
  className?: string;
  isRequired: boolean;
  placeholder: string;
  validationError: string;
  locale: Locale;
}

const Postcode = ({
  className,
  isRequired,
  locale,
  placeholder,
  validationError,
}: PostcodeProps) => {
  const { postcode, error, handleChange, handleBlur } = usePostcodeData(isRequired, locale);

  const Error = () => <span className={styles.error}>{error ? validationError : ''}</span>;

  return (
    <Input
      type="text"
      placeholder={placeholder}
      rightIcon={<Location />}
      maxLength={8}
      name="postcode"
      value={postcode}
      required={isRequired}
      onChange={handleChange}
      onBlur={handleBlur}
      error={<Error />}
      id="postcode"
      dataTestId="postcode"
      autoComplete="postcode"
      aria-label="postcode"
      className={className}
    />
  );
};

export default Postcode;
