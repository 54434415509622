import { Search } from '@gds/Icons/Paths/Search';
import { Input } from '@gds/Input/Input';

import { Props } from './QuickSearch.entity';

import styles from './QuickSearch.module.css';

const QuickSearch = ({ onChange, placeHolder, dataTestId, dropdownType }: Props) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.value);
  };

  return (
    <div className={styles.quickSearch}>
      <Input
        placeholder={placeHolder}
        leftIcon={<Search className={styles.icon} />}
        onChange={handleInputChange}
        type="text"
        name={dropdownType}
        id={dropdownType}
        autoComplete="off"
        aria-label={placeHolder}
        dataTestId={dataTestId}
        fullWidth
      />
    </div>
  );
};

export default QuickSearch;
