import { useState } from 'react';

import { postcodeAtom } from '@core/Atoms/Postcode/Postcode.atom';
import { aggregationsAtom } from '@core/Atoms/Search/AggregationsAtom';
import Button from '@gds/Button/Button';
import { isMobileAppAtom } from '@mobile-app/Atoms/IsMobileApp.atom';
import { useAtomValue } from 'jotai';

import { locationAggregationsAtom } from 'Atoms/Search/LocationAggregationAtom/LocationAggregationAtom';
import { locationAtom } from 'Atoms/Search/PostcodeAtom/LocationAtom';

import { SearchCTAProps } from '../../Search.entity';

const SearchCTA = ({
  searchButton,
  loadingText,
  locale,
  disabled,
  showLoadingCta,
  isLocationPage = false,
}: SearchCTAProps) => {
  const { totalCount = 0, isLoading } = useAtomValue(
    isLocationPage ? locationAggregationsAtom : aggregationsAtom,
  ) as { totalCount: number; isLoading: boolean };
  const postcode = useAtomValue(postcodeAtom);
  const { isValid } = useAtomValue(locationAtom);
  const [shouldShowLoader, setShouldShowLoader] = useState(false);
  const isMobileApp = useAtomValue(isMobileAppAtom)!;

  const handleClick = () => {
    if ((isValid && locale === 'uk' && !!postcode[locale]) || locale === 'fr' || showLoadingCta) {
      !isMobileApp && setShouldShowLoader(true);
    }
  };
  const buttonText = isLoading ? loadingText : searchButton;
  const isButtonDisabled = isLocationPage ? disabled : totalCount === 0 || disabled;

  return (
    <Button
      fullWidth
      variant="contained"
      aria-label="search-button"
      onClick={handleClick}
      disabled={isButtonDisabled}
      loading={shouldShowLoader}
      type="submit"
      data-test-id="search-button"
    >
      {buttonText}
    </Button>
  );
};

export default SearchCTA;
