import { useState, useRef, useEffect } from 'react';

import { MakeModelAtom } from '@core/Entities/Search/Atoms/MakeAtom.entity';
import { ChevronDown } from '@gds/Icons/Paths/ChevronDown';
import { useAtom } from 'jotai';

import { makeAtom } from 'Atoms/Search/MakeAtom/MakeAtom';
import { modelAtom } from 'Atoms/Search/ModelAtom/ModelAtom';

import { MakeObject, MakeProps } from '../../Search.entity';
import styles from '../../Search.module.css';

import CustomDropDown from '../CustomDropDown/CustomDropDown';

const Make = ({
  makePlaceholder,
  modelPlaceholder,
  searchPlaceholder,
  noResultText,
  makeTitle,
  state,
  updateFn,
  defaultCount,
  isReviewsPage,
  reviewItems,
}: MakeProps) => {
  const DEFAULT_OPTION = {
    key: 'any_make',
    displayName: makePlaceholder,
    count: defaultCount,
    make: 'anymake',
  };
  const makeRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredMakes, setFilteredMakes] = useState<MakeModelAtom[]>([]);

  const [selectedMake, setSelectedMake] = useAtom(makeAtom);

  const [_, setSelectedModel] = useAtom(modelAtom);

  const handleMenuOpen = () => {
    setIsOpen(isOpen => !isOpen);
    setSearchKeyword('');
  };

  const eligibleMakes = isReviewsPage
    ? (reviewItems as MakeObject[])
    : (state.makes as MakeModelAtom[]);

  useEffect(() => {
    if (searchKeyword) {
      const filteredArr = eligibleMakes?.filter(data => {
        return data.displayName.toLowerCase().includes(searchKeyword);
      });
      setFilteredMakes(searchKeyword ? filteredArr : []);
    }
  }, [searchKeyword]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!makeRef?.current?.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [makeRef]);

  const handleSearchChange = (value: string) => {
    setSearchKeyword(value.toLowerCase());
  };

  const handleMenuClose = (option: MakeModelAtom) => {
    if (option) {
      setSelectedMake(option);
      updateFn({ ...state, totalCount: option.count });
      setSelectedModel({
        key: 'any_model',
        displayName: modelPlaceholder,
        count: option.count,
        make: 'anymodel',
      });
      setIsOpen(false);
    }
  };

  return (
    <div
      className={`${styles.makeModelWrapper} ${isOpen ? styles.active : ''}`}
      ref={makeRef}
      data-test-id="make-component"
      aria-label="make-value"
    >
      <div
        className={`${styles.dropdownWrapper} ${styles.first}`}
        onClick={handleMenuOpen}
        data-test-id="make-menu-button"
      >
        <span className={styles.makeValue} data-test-id="make-value">
          {selectedMake?.key === 'any_make' ? makePlaceholder : selectedMake?.displayName}
        </span>
        <div className={styles.iconWrapper}>
          <ChevronDown className={isOpen ? styles.rotate : ''} />
        </div>
      </div>
      {isOpen && (
        <CustomDropDown
          items={
            searchKeyword.length > 0 ? filteredMakes : [DEFAULT_OPTION, ...(eligibleMakes ?? [])]
          }
          isGrouped
          onSelect={handleMenuClose}
          checkedValue={selectedMake?.make}
          dataTestId="make-menu"
          searchPlaceholder={searchPlaceholder}
          handleSearchChange={handleSearchChange}
          noResultText={noResultText}
          title={makeTitle}
          handleMenuOpen={handleMenuOpen}
          countLabel={!isReviewsPage}
        />
      )}
    </div>
  );
};

export default Make;
