import { FC, ReactNode } from 'react';

import { Typography } from '@gds/Typography/Typography';
import Image from 'next/image';

import { Logos, MakeLogos } from './Logos';

import styles from './MakeLabel.module.css';
interface MakeLabelProps {
  count?: number;
  make: Logos;
  name: string;
  hideLogo?: boolean;
  countLabel?: boolean;
  nameRender?: ReactNode;
}

const MakeLabel: FC<MakeLabelProps> = ({
  count,
  name,
  make,
  hideLogo = false,
  countLabel = true,
  nameRender = null,
}) => {
  const imageURL = !hideLogo && MakeLogos[make]?.src;

  return (
    <div className={styles.makeLabel}>
      {imageURL && !hideLogo && (
        <Image
          className={styles.logo}
          width={24}
          height={24}
          alt={''}
          src={imageURL}
          data-test-id="make-logo"
        />
      )}
      <div className={styles.name}>
        <Typography variant="subheading4" className="subHeading">
          {nameRender ?? name}
        </Typography>
        {countLabel && count !== undefined && count > 0 && (
          <Typography variant="caption2" Component="span" data-test-id="make-count">
            ({count})
          </Typography>
        )}
      </div>
    </div>
  );
};

export default MakeLabel;
