import { ChangeEvent, FocusEvent, useEffect } from 'react';

import { postcodeAtom } from '@core/Atoms/Postcode/Postcode.atom';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { useAtom } from 'jotai';

import { locationAtom } from 'Atoms/Search/PostcodeAtom/LocationAtom';
import validatePostcode from 'Utils/Search/ValidatePostcode';

const usePostcodeData = (isRequired: boolean, locale: Locale) => {
  const [locationState, setLocationState] = useAtom(locationAtom);
  const [postcode, setPostcode] = useAtom(postcodeAtom);
  const postcodeVal = postcode[locale];
  const { location, error, placeName } = locationState;

  const shouldTriggerValidation = (postcode: string, valid: boolean) => {
    if ((isRequired && !valid) || (!isRequired && postcode && !valid)) {
      return true;
    }
    return false;
  };

  const checkIsValid = async () => {
    const response = await validatePostcode(postcodeVal, locale);

    if (response) {
      const { isValid, location, placeName = '' } = response;
      setLocationState({
        ...locationState,
        error: shouldTriggerValidation(postcodeVal, isValid) ? true : false,
        isValid,
        ...(location && { location: { lat: location.lat, lon: location.lon } }),
        ...(placeName && { placeName }),
      });
      setPostcode({
        ...postcode,
        [locale]: postcodeVal,
      });
    }
  };

  useEffect(() => {
    if (!postcodeVal || !postcodeVal.trim()) {
      setLocationState({
        ...locationState,
        error: false,
      });
      return;
    }

    if (postcodeVal) checkIsValid();
  }, [postcodeVal]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPostcode({
      ...postcode,
      [locale]: event.target.value?.toUpperCase(),
    });
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setPostcode({
        ...postcode,
        [locale]: event.target.value?.toUpperCase(),
      });
    }
    checkIsValid();
  };

  return {
    postcode: postcodeVal,
    location,
    error,
    placeName,
    handleChange,
    handleBlur,
  };
};

export default usePostcodeData;
