import { MakeModelAtom } from '@core/Entities/Search/Atoms/MakeAtom.entity';

import { RegionAreaAtom } from 'Atoms/Search/RegionAtom/RegionAtom.entity';

import { Logos } from '../../MakeLabel/Logos';
import MakeLabel from '../../MakeLabel/MakeLabel';
import { DropdownType } from '../CustomDropDown.entity';

import styles from './CustomDropDownItem.module.css';
interface CustomDropDownItems {
  checkedValue: string;
  list: MakeModelAtom | RegionAreaAtom;
  handleChange: (value: MakeModelAtom) => void;
  dropdownType: DropdownType;
  countLabel?: boolean;
}

export const CustomDropDownItem = ({
  list,
  checkedValue,
  handleChange,
  dropdownType,
  countLabel,
}: CustomDropDownItems) => {
  const { make, displayName, count, key } = list;
  const makeModelName = dropdownType === 'make' ? `${make}` : `${make}-${key}`;

  return (
    <label
      htmlFor={makeModelName}
      aria-labelledby={makeModelName}
      className={`${styles.labelWrapper} ${checkedValue === key && styles.active}`}
    >
      <input
        id={makeModelName}
        onChange={() => handleChange(list)}
        disabled={false}
        name={dropdownType}
        type="radio"
        value={key}
        checked={key === checkedValue}
      />
      <MakeLabel
        name={displayName}
        count={count}
        make={make as Logos}
        hideLogo={dropdownType !== 'make'}
        countLabel={countLabel}
      />
    </label>
  );
};
