import { LocationState } from '@core/Entities/Search/LocationState.entity';

import { createCookieStorage } from '@core/Utils/CookieStorage';
import { atomWithStorage } from 'jotai/utils';

const defaultValue = {
  isValid: false,
  location: {
    lat: 0,
    lon: 0,
  },
  error: false,
  placeName: '',
};

export const locationAtom = atomWithStorage<LocationState>(
  'locationValue',
  defaultValue,
  createCookieStorage<LocationState>(defaultValue),
);
