export function formatUkPostcode(postcode: string): string {
  // Remove existing spaces
  const cleanedPostcode = postcode.replace(/\s/g, '');

  // Validate the postcode structure
  const pattern = /^[a-z]{1,2}\d[a-z\d]?\d[a-z]{2}$/i;
  if (!pattern.test(cleanedPostcode)) {
    return 'Invalid postcode';
  }

  // Determine where to insert the space
  // the last part of post code will always be 1 number and 2 letters
  const spaceIndex = cleanedPostcode.length - 3;

  // Insert the space at the determined index
  return cleanedPostcode.slice(0, spaceIndex) + ' ' + cleanedPostcode.slice(spaceIndex);
}
