'use client';

import { FC } from 'react';

import Image from 'next/image';

import { kontentLoader } from './KontentLoader';

type ClientImageProps = React.ComponentProps<typeof Image>;

export const KenticoImage: FC<ClientImageProps & { alt: string }> = ({ alt, ...props }) => (
  <Image {...props} alt={alt} loader={kontentLoader} />
);
