import { MakeModelAtom } from '@core/Entities/Search/Atoms/MakeAtom.entity';

export const getFilteredModels = (
  models: MakeModelAtom[],
  selected: MakeModelAtom | null,
): MakeModelAtom[] => {
  if (selected?.key === 'any_make') return [];
  if (selected) {
    const { make: selectedMake } = selected;

    return models.filter(model => {
      const { make, count = 0 } = model;
      // filter out models with selected make and count
      return make.toLowerCase().includes(selectedMake.toLowerCase()) && count > 0;
    });
  }
  return models;
};
