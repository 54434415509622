/* eslint-disable @typescript-eslint/naming-convention */
import { StaticImageData } from 'next/image';

import abarth from './Logos/abarth.webp';
import acura from './Logos/acura.webp';
import aixam from './Logos/aixam.webp';
import alfaRomeo from './Logos/alfa-romeo.webp';
import alpina from './Logos/alpina.webp';
import alpine from './Logos/alpine.webp';
import artega from './Logos/artega.webp';
import asiaAotors from './Logos/asia-motors.webp';
import astonMartin from './Logos/aston-martin.webp';
import audiLogo from './Logos/audi.webp';

import bentley from './Logos/bentley.webp';
import bmw from './Logos/bmw.webp';
import bugatti from './Logos/bugatti.webp';
import byd from './Logos/byd.webp';

import cadillac from './Logos/cadillac.webp';
import caterham from './Logos/caterham.webp';
import chevrolet from './Logos/chevrolet.webp';
import chrysler from './Logos/chrysler.webp';
import citroen from './Logos/citroen.webp';
import corvette from './Logos/corvette.webp';
import cupra from './Logos/cupra.webp';

import dacia from './Logos/dacia.webp';
import daewoo from './Logos/daewoo.webp';
import daihatsu from './Logos/daihatsu.webp';
import dodge from './Logos/dodge.webp';
import dsAutomobiles from './Logos/ds-automobiles.webp';

import ferrari from './Logos/ferrari.webp';
import fiat from './Logos/fiat.webp';
import fisker from './Logos/fisker.webp';
import ford from './Logos/ford.webp';

import genesis from './Logos/genesis.webp';
import gmc from './Logos/gmc.webp';
import greatWall from './Logos/great-wall.webp';
import gwmOra from './Logos/gwm-ora.webp';

import honda from './Logos/honda.webp';
import hummer from './Logos/hummer.webp';
import hyundai from './Logos/hyundai.webp';

import ineos from './Logos/ineos.webp';
import infiniti from './Logos/infiniti.webp';
import isuzu from './Logos/isuzu.webp';
import iveco from './Logos/iveco.webp';

import jaguar from './Logos/jaguar.webp';
import jeep from './Logos/jeep.webp';

import kia from './Logos/kia.webp';
import ktm from './Logos/ktm.webp';

import lada from './Logos/lada.webp';
import lamborghini from './Logos/lamborghini.webp';
import lancia from './Logos/lancia.webp';
import landRover from './Logos/land-rover.webp';
import levc from './Logos/levc.webp';
import lexus from './Logos/lexus.webp';
import ligier from './Logos/ligier.webp';
import lotus from './Logos/lotus.webp';
import lynkAndCo from './Logos/lynk-and-co.webp';

import man from './Logos/man.webp';
import maserati from './Logos/maserati.webp';
import maxus from './Logos/maxus.webp';
import maybach from './Logos/maybach.webp';
import mazda from './Logos/mazda.webp';
import mclaren from './Logos/mclaren.webp';
import mercedesBenz from './Logos/mercedes.webp';
import mg from './Logos/mg.webp';
import microcar from './Logos/microcar.webp';
import mini from './Logos/mini.webp';
import mitsubishi from './Logos/mitsubishi.webp';
import morgan from './Logos/morgan.webp';

import nissan from './Logos/nissan.webp';

import opel from './Logos/opel.webp';

import peugeot from './Logos/peugeot.webp';
import piaggio from './Logos/piaggio.webp';
import polestar from './Logos/polestar.webp';
import porsche from './Logos/porsche.webp';

import ram from './Logos/ram.webp';
import renault from './Logos/renault.webp';
import rollsRoyce from './Logos/rolls-royce.webp';
import rover from './Logos/rover.webp';

import saab from './Logos/saab.webp';
import seat from './Logos/seat.webp';
import skoda from './Logos/skoda.webp';
import smart from './Logos/smart.webp';
import ssangyong from './Logos/ssangyong.webp';
import subaru from './Logos/subaru.webp';
import suzuki from './Logos/suzuki.webp';

import tesla from './Logos/tesla.webp';
import toyota from './Logos/toyota.webp';

import vauxhall from './Logos/vauxhall.webp';
import volkswagen from './Logos/volkswagen.webp';
import volvo from './Logos/volvo.webp';

import wiesmann from './Logos/wiesmann.webp';

export const ALL_LOGOS = [
  'abarth',
  'acura',
  'alfa_romeo',
  'alpina',
  'alpine',
  'artega',
  'asia_motors',
  'aston_martin',
  'audi',
  'aixam',
  'bentley',
  'bmw',
  'bugatti',
  'byd',
  'cadillac',
  'caterham',
  'chevrolet',
  'chrysler',
  'citroen',
  'corvette',
  'cupra',
  'dacia',
  'daewoo',
  'dodge',
  'ds',
  'ds_automobiles',
  'daihatsu',
  'ferrari',
  'fiat',
  'fisker',
  'ford',
  'genesis',
  'gmc',
  'great_wall',
  'gwm_ora',
  'honda',
  'hummer',
  'hyundai',
  'ineos',
  'infiniti',
  'isuzu',
  'iveco',
  'jaguar',
  'jeep',
  'kia',
  'ktm',
  'lada',
  'lamborghini',
  'lancia',
  'land_rover',
  'levc',
  'lexus',
  'ligier',
  'lotus',
  'lynk_and_co',
  'man',
  'maserati',
  'maxus',
  'maybach',
  'mazda',
  'mclaren',
  'mercedes_benz',
  'mg',
  'mg_motor_uk',
  'microcar',
  'mini',
  'mitsubishi',
  'morgan',
  'nissan',
  'opel',
  'peugeot',
  'piaggio',
  'polestar',
  'porsche',
  'ram',
  'renault',
  'rolls_royce',
  'rover',
  'saab',
  'seat',
  'skoda',
  'smart',
  'ssangyong',
  'subaru',
  'suzuki',
  'tesla',
  'toyota',
  'vauxhall',
  'volkswagen',
  'vw',
  'volvo',
  'wiesmann',
] as const;

export type Logos = (typeof ALL_LOGOS)[number];

export const MakeLogos: Record<Logos, StaticImageData> = {
  abarth,
  acura,
  alfa_romeo: alfaRomeo,
  alpina,
  alpine,
  artega,
  asia_motors: asiaAotors,
  aston_martin: astonMartin,
  audi: audiLogo,
  aixam,
  bentley,
  bmw,
  bugatti,
  byd,
  cadillac,
  caterham,
  chevrolet,
  chrysler,
  citroen,
  corvette,
  cupra,
  dacia,
  daewoo,
  dodge,
  ds: dsAutomobiles,
  ds_automobiles: dsAutomobiles,
  daihatsu,
  ferrari,
  fiat,
  fisker,
  ford,
  genesis,
  gmc,
  great_wall: greatWall,
  gwm_ora: gwmOra,
  honda,
  hummer,
  hyundai,
  ineos,
  infiniti,
  isuzu,
  iveco,
  jaguar,
  jeep,
  kia,
  ktm,
  lada,
  lamborghini,
  lancia,
  land_rover: landRover,
  levc,
  lexus,
  ligier,
  lotus,
  lynk_and_co: lynkAndCo,
  man,
  maserati,
  maxus,
  maybach,
  mazda,
  mclaren,
  mercedes_benz: mercedesBenz,
  mg: mg,
  mg_motor_uk: mg,
  microcar,
  mini,
  mitsubishi,
  morgan,
  nissan,
  opel,
  peugeot,
  piaggio,
  polestar,
  porsche,
  ram,
  renault,
  rolls_royce: rollsRoyce,
  rover,
  saab,
  seat,
  skoda,
  smart,
  ssangyong,
  subaru,
  suzuki,
  tesla,
  toyota,
  vauxhall,
  volkswagen: volkswagen,
  vw: volkswagen,
  volvo,
  wiesmann,
};
