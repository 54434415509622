type Grouped<T> = {
  items: { item: T; key: string }[];
  title?: string;
};

export const groupByField = <T>(
  items: T[],
  field: keyof T,
  isGrouped = false,
  keyAsTitle = false,
  // NOTE: this is experimental due to time limit.
  // should work anytime T has the prop label that is a string
  shouldOrder?: boolean,
  nameAsTitle?: boolean,
): Grouped<T>[] => {
  const groupedItems: Record<string, Grouped<T>> = {};

  items.forEach(item => {
    const key = item[field] as string;
    let groupTitle = '';
    if (isGrouped) {
      if (nameAsTitle) {
        groupTitle = keyAsTitle ? key : item.displayName.charAt(0).toUpperCase();
      } else {
        groupTitle = keyAsTitle ? key : key.charAt(0).toUpperCase();
      }
    }

    if (!groupedItems[groupTitle]) {
      if (isGrouped) {
        groupedItems[groupTitle] = { title: groupTitle, items: [] };
      } else {
        groupedItems[groupTitle] = { items: [] };
      }
    }

    groupedItems[groupTitle].items.push({ item, key });

    if (shouldOrder) {
      groupedItems[groupTitle].items.sort((a, b) => {
        if (a.item.label! < b.item.label!) return -1;
        if (a.item.label! > b.item.label!) return 1;
        return 0;
      });
    }
  });

  return Object.values(groupedItems).sort((a, b) => {
    if (a.title! < b.title!) return -1;
    if (a.title! > b.title!) return 1;
    return 0;
  });
};
